<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-app>
        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>

    export default {

        name: 'PopupLayout',
        data: () => ({


        }),
        methods: {

            log() {
            }
        },

        watch: {
            group() {
                this.drawer = false
            },

        },
    };

    $(function () {

    });
</script>

<style>

</style>
